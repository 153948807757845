// UnderConstruction.tsx
import React from 'react';
import './UnderConstruction.css'; // We'll add the styles here later
import underConstruction from "../../Media/Utils/UnderConstruction.webp"
const UnderConstruction: React.FC = () => {
  return (
    <div className="under-construction">
      <div className="logo-placeholder">
        <img className='hero' src={underConstruction} alt='construction-banner'/>
      </div>
      <h1>Our Website is Under Construction</h1>
      <p>We're working hard to give you a better experience. Stay tuned!</p>
    </div>
  );
};

export default UnderConstruction;
